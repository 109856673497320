import dayjs from 'dayjs';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../store';
import { fetchPaymentsAsync } from '../../../store/slices/paymentsSlice';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h3`
  color: #545970;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #24252d;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
`;

const List = styled.ul`
  max-height: 360px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444650;
  padding: 8px 0;

  span:last-of-type {
    font-weight: 600;
  }
`;

const NoPayments = styled.p`
  margin-bottom: 16px;
`;

function PaymentHistory({ auth, isCalendar, user, toogleCalendar }) {
  const dispatch = useAppDispatch();
  const payments = useAppSelector((state) => state.payments);
  const filteredPayments = payments.filter(
    (payment) => payment.user === user?._id
  );

  useEffect(() => {
    dispatch(fetchPaymentsAsync());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title>Payments</Title>
      {filteredPayments.length === 0 ? (
        <NoPayments>No payments</NoPayments>
      ) : (
        <List>
          {filteredPayments.map((payment) => {
            return (
              <Item key={payment._id}>
                <span>
                  {dayjs(payment.from).format('DD.MM.YY')} -{' '}
                  {dayjs(payment.to).format('DD.MM.YY')}
                </span>
                <span>{payment.price} USD</span>
              </Item>
            );
          })}
        </List>
      )}
    </Wrapper>
  );
}

export default PaymentHistory;
